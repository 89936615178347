.login-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #101214;
  padding-top: 113px;
  padding-bottom: 110px;
}
.sigin-text
{
  text-align: unset;
}
.button-login-1
{
  margin-top :50px !important
}
.login-page img {
  width: 244px;
  margin-bottom: 60px;
}
.label_user {
  font-family: InterSemibold;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;

  background: linear-gradient(90.24deg, #dce64f 0.06%, #f8ff96 43.96%);
  background-clip: text;
  color: transparent;
  margin: 0px !important;
  margin-bottom: 10px !important;
}
.login-page h1 {
  margin: 0px;
}

.login-page p {
  /* padding-bottom: 24px; */
  /* border-bottom: 1px solid #f2f2f4; */
  /* margin-bottom: 40px; */
}
.form-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 65px;
  margin-bottom: 53px;
}

.username-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.labeluser {
  font-family: InterSemibold;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
  color:#F4F6F7;
  margin: 0px !important;
}
.input-field1 {
  padding: 18px 20px;
  border-radius: 20px;
  background: #101214;
  height: 60px;
  font-family: InterMedium !important;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 19.5px;
  text-align: left;
  color: rgba(244,245,247,0.5);
  width: 100%;
  border:transparent
}
.input-field1::placeholder {
  color: rgba(244,245,247,0.5);
  font-family: InterMedium !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
}
.input-field1:focus {
  border-color: transparent;
  outline: none;
}
.username-form1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.password-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.input-field2 {
  padding: 18px 20px;
  border-radius: 20px;
  border: transparent;
  background: #101214;
  height: 60px;
  font-family:InterMedium;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 19.5px;
  text-align: left;
  color: rgba(244,245,247,0.5);
  position: relative;
  width: 100%;
}
.input-field2::placeholder {
  color: rgba(244,245,247,0.5);
  font-family: InterMedium;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
}
.input-field2:focus {
  border-color: transparent;
  outline: none;
}

.forgot-password a {
  width: 100%;
  height: 20px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  /* font-family: 'Roboto'; */
}

.remember-container {
  display: flex;
  /* gap:9px */
  padding-left: 10px;
}
.forget-text {
 
  color:#FE498A;
  font-family: InterMedium !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}


/* .login-page .css-q8hpuo-MuiFormControl-root{
    margin-top: 42px;
    border-radius: 20px!important;
 } */

/* .login-page .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    color: #3456d0;
 }
  */
/* .login-page .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 12px;
    border: 1px solid #dee9fc;
    margin-bottom: 45px;
    height: 50px;
 } */
eye-icon

/* .login-page .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 12px;
    height: 50px;
 } */

.login-page .MuiInputBase-root {
  border-radius: 30px !important;
  height: 60px !important;
  border: 1px solid #ffffff33;
}
.eye-icon {
  position: absolute;
  width: 24px !important;
  margin-bottom: 0px !important;
  right: 0;
  transform: translate(-55px, 18px);
  margin-right: 20px;
}

/* MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl css-md26zr-MuiInputBase-root-MuiOutlinedInput-root */

/* .login-page .MuiButton-containedPrimary{
    border-radius: 20px!important;
    height: 40px;
    text-transform: none;
    background-color: #3350ce;
 } */

/* MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation css-zddlty-MuiButtonBase-root-MuiButton-root */

.login-page .MuiCheckbox-root {
  /* color: #3350ce!important; */
}
:where(.css-dev-only-do-not-override-1gwfwyx).ant-card .ant-card-body {
  padding: 0px !important;
  /* padding-bottom: 25px !important; */
}
/* MuiButtonBase-root MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-sizeMedium PrivateSwitchBase-root MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-sizeMedium MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-sizeMedium css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root */

@media (max-width: 600px) {
  .input-field1,
  .input-field2 {
    width: 100%;
  }
  .login-page-card {
    width: 100% !important;
    padding: 20px !important;
  }
  .sigin-text
{
  text-align: center;
}
.form-container
{
  margin-top:0px;
  margin-bottom: 30px;
  gap:10px
}
.button-login-1
{
  margin-top:30px !important
}
}

@media (max-width: 600px) {
  .login-page {
    padding-left:20px;
    padding-right:20px;
  }
  .eye-icon
  {
    margin-right: 0px;
  }
}
