.button-nav
{
    color:white !important
}
:where(.css-dev-only-do-not-override-1gwfwyx).ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover, :where(.css-dev-only-do-not-override-1gwfwyx).ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover {
    background-color: rgba(254, 241, 246, 0.1) !important;
    height:47px !important
}
:where(.css-dev-only-do-not-override-1gwfwyx).ant-menu .ant-menu-submenu-expand-icon, :where(.css-dev-only-do-not-override-1gwfwyx).ant-menu .ant-menu-submenu-arrow 
{
    display: none !important;
}
:where(.css-14qglws).ant-menu .ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow 
{
    display: none !important;
}
:where(.css-14qglws).ant-menu-inline-collapsed .ant-menu-submenu-arrow::before, :where(.css-14qglws).ant-menu-inline .ant-menu-submenu-arrow::before {
display: none !important;
}
:where(.css-dev-only-do-not-override-14qglws).ant-menu .ant-menu-submenu-arrow::before, :where(.css-dev-only-do-not-override-14qglws).ant-menu .ant-menu-submenu-arrow::after 
{
    display: none !important;
}
.dropdown-icon-nav
{
    height: 15px;
    width: 15px;
    color:rgba(244,246,247,0.5);
    margin-left: 100px;
}
@media (max-width:1070px)
{
 .button-nav
 {
    margin-top: 0px !important;
 }
}