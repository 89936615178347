body {
background: #111214 !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@font-face {
  font-family: "Roboto";
  src: url('../public/FontFamily/Roboto-Medium.ttf') format("truetype");
}
@font-face {
  font-family: "Inter";
  src: url('../public/FontFamily/Inter_28pt-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "InterBold";
  src: url('../public/FontFamily/Inter_18pt-Bold.ttf') format("truetype");
}
@font-face {
  font-family: "InterMedium";
  src: url('../public/FontFamily/Inter_24pt-Medium.ttf') format("truetype");
}
@font-face {
  font-family: "InterSemibold";
  src: url('../public/FontFamily/Inter_28pt-SemiBold.ttf') format("truetype");
}
@font-face {
  font-family: "Montserrat";
  src: url('../public/FontFamily/Montserrat-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url('../public/FontFamily/Montserrat-Medium.ttf') format("truetype");
}
@font-face {
  font-family: "Montserrat-Semibold";
  src: url('../public/FontFamily/Montserrat-SemiBold.ttf') format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url('../public/FontFamily/Montserrat-Bold.ttf') format("truetype");
}

/* *{
  background-color: #0E0E0E ;
} */

