body {
  background-color: #101214;
}
.edit-task-page-header {
  width: 100%;
  height: 65px;
  /* margin-left: 50px; */
  background-color: #000000;
  /* padding-top:32px */
}
.edit-task-page {
  transition: transform 0.3s ease-in-out;
}

.edit-task-page.shifted {
  transform: translateX(250px); /* Adjust based on your drawer width */
}

.edit-task-page-body {
  width: 100%;
  min-height: 100px;
  background-color: #101214;
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  margin-top: 10px;
}
.task__group__placeholder
{
  color: #9EA0A5 !important
}
.edit-task-page {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  overflow-x: hidden !important;
  /* margin-left: 50px; */
}
.edit-task-page-header {
  display: flex;
  align-items: center;
  flex-direction: row;
}

/* .edit-task-page-header h2 {
  margin-left: 10px;
} */

input::placeholder {
  color: rgba(9, 72, 19, 0.4);
  font-size: 16px;
  font-weight: 600;
}
.edit-task-page-body-left textarea::placeholder {
  font-size: 16px;
  font-weight: 600;
  color: #09481366;
}
.input-number {
  color: white;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 18.75px;
  text-align: left;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select
  .input-number1:focus {
  outline: none;
}
.input-number1::placeholder
{

}
.input-number1 {
  color: #09481333;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 18.75px;
  text-align: left;
}

.input-url {
  font-size: 16px;
  font-weight: 600;
}
/* .edit-task-page-header h4 {
  color: #9f9fa0;
  text-align: end;
  margin-top: 27px;
} */

.edit-task-page-body-left {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 30px 30px;
}

.edit-task-page-body-left > div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.edit-task-page-body-left label {
  font-weight: bold;
  margin-bottom: 13px;
}

.task_title1
{
   height:155px !important;
   /* width:659px; */
   border-radius: 16px !important;
   background: #24262B !important;
   border:transparent !important;
   padding-top: 22px !important;
   padding-left: 20px !important;
   color: #9EA0A5 !important;

   font-family: Montserrat-Medium !important;
   font-size: 16px !important;
   font-weight: 500 !important;
   line-height: 19.5px !important;
   text-align: left !important;
  
margin:0px !important


}
.edit-task-page-body-left input[type="text"],
.edit-task-page-body-left input[type="url"],
.edit-task-page-body-left input[type="number"] {
  height: 65px;
  border: none !important;
  padding: 5px 20px !important;
  outline: none;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 19.5px;
  border-radius: 16px !important;
  background: #24262B !important;

  color: #9ea0af !important;
  font-family: Montserrat-Medium !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.edit-task-page-body-left option {
  height: 35px;
  background-color: #fafafb;
}

.edit-task-page-body-left textarea {
  border: 1px solid #efefef;
  padding: 10px 10px;
  background-color: #fafafb;
  outline: none;
  border-radius: 5px;
  resize: none;
  border: 2px solid rgba(18, 18, 18, 1);
  background: #24262B !important;

  height: 155px;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
}

/* .edit-task-page-body-left>div{
    margin-bottom: 30px;
} */

.edit-task-page-body-left .MuiInputBase-root {
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 500;
  color: #ffffff;
  line-height: 19.5px;
  border: 2px solid rgba(18, 18, 18, 1);
  background-color: rgba(14, 14, 14, 1);
}
Publi
.css-6hp17o-MuiList-root-MuiMenu-list {
   background-color: rgba(254, 241, 246, 0.1) !important; 
  border: unset !important;
  border-radius: 20px !important;

}

/* MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary  css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root */

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select:hover {
  border: none;
}

.edit-page-left-button-group {
  display: flex !important;
  flex-direction: row !important;
  gap: 20px;
  margin-top: 15px;
  margin-bottom: 337px;
}
.edit-page-left-button-group button {
  border-radius: 20px;
  width: 190px;
  height: 60px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  text-align: left;
}

.edit-page-left-button-group .MuiButton-contained {
  border-radius: 16px;
  width: 190px;
  height: 60px;
  font-family: Montserrat-Semibold;
font-size: 18px;
font-weight: 600;
line-height: 18px;
text-align: center;
color: #FFFFFF;
  background: rgba(22, 87, 255, 1) !important;
}
.css-pwxzbm {
  background-color: rgba(254,241,246,0.1) !important;
  border-radius: 10px !important;
  padding: 0px 10px !important;
  /* border: 2px solid rgba(18, 18, 18, 1) !important; */
  margin-top: 5px !important;
}
/* MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation css-zddlty-MuiButtonBase-root-MuiButton-root */

.edit-page-left-button-group .MuiButton-outlined {
  border-radius: 16px !important;
  background: #393C43 !important;
  
  border:none !important;
  color: white;
  font-family: Montserrat-Semibold !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  
  height: 60px !important;
  width: 190px !important;
}
.task_title::placeholder {
  color: rgba(244, 245, 247, 0.5) !important;
  font-size: 16px;
  font-weight: 500 !important;
  font-family: InterMedium !important;
}
.task_title1::placeholder {
    color: rgba(244, 245, 247, 0.5) !important;
    font-size: 16px;
    font-weight: 500 !important;
    font-family: InterMedium !important;
    
  }
  .task_title1:focus
  {
    border-color: unset;
    outline: none;
  }
.input-number1::placeholder {
  color: rgba(244, 245, 247, 0.5) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: InterMedium !important;
}
/* MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary css-1rwt2y5-MuiButtonBase-root-MuiButton-root */

.edit-task-page-body-right {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 3%;
  box-sizing: border-box;
}

.edit-task-page-body {
  display: flex;
  flex-direction: row;
}
.edit-task-page-body-right .MuiDropzonePreviewList-image {
  max-height: none !important;
  max-width: none !important;
  height: 381px !important;
  transform: translateY(-62px);
  width: 381px !important;
}

.edit-task-page-body-right .MuiDropzoneArea-icon {
  color: rgba(255, 255, 255, 0.1);
}

.edit-task-page-body-right .MuiTypography-h5 {
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 18.75px !important;
  text-align: center !important;
  color: rgba(255, 255, 255, 0.1);
}
.edit-task-page-body-right .MuiTypography-h5::after {
  content: "Browse";
  color: white;
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 18.75px !important;
  text-decoration: underline;
}

/* .edit-task-page-body-right .MuiDropzoneArea-root{
    margin-top: 24px;
} */

.imagesize {
  font-family: InterMedium !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 17.07px !important;
  text-align: left !important;
  color: rgba(244, 245, 247, 0.5) !important;
  margin-top: 4px !important;
}
.MuiDropzoneArea-root {
  display: flex;
  align-items: center !important;
  flex-direction: column !important;
  justify-content: center !important;
  border-color: rgba(40, 40, 40, 1) !important;
  background-color: #24262b !important;
  margin-top: 4px !important;
  height: 381px !important;
  width: 381px;
  
}
.MuiDropzoneArea-textContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 10px 20px !important;
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background-color: #0e0e0e !important;
  border-radius: 10px !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
}
@media (max-width: 768px) {
  .edit-task-page-body {
    flex-direction: column;
  }
  .edit-task-page-body-left {
    width: 100% !important;

  }
  .edit-task-page-body-right {
    width: 100%;
  }
  .edit-page-left-button-group button {
    width: 50%;
  }
}
@media (max-width: 425px) {
  .edit-task-page-body-left {
    padding: 15px 15px;
  }
  .MuiDropzoneArea-root {
    width: 100% !important;
  }
  .input-number1::placeholder {
    color: rgba(255, 255, 255, 0.7);
    font-size: 10px;
    font-weight: 600;
    font-family: Montserrat;
  }
  .task_title::placeholder {
    color: rgba(255, 255, 255, 0.7);
    font-size: 11px;
    font-weight: 600;
    font-family: Montserrat;
  }
}
.MuiDropzoneArea-root {
  width: 100% !important;
}
@media (max-width: 1000px) {
  .MuiDropzoneArea-root {
    width: 100% !important;
  }
}



