.header-h1-1{
    /* margin-left:50px */
    margin: 0px 0px 12px 20px !important;
    font-family: InterBold !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 29.05px !important;
  text-align: left !important; 
  color:#F4F6F7 !important;
  }
  /* DropdownStyles.css */
  .button-week {
    display: flex;
    padding: 13px 17px 15px !important;
    border-radius: 16px !important;
    margin-right: 10px !important;
    height: 45px !important;
    width: 131px !important;
    border: 1px solid #509DFF;
    background: rgba(254, 241, 246, 0.1);
    font-family: Montserrat-Semibold !important;
    color: #9EA0A5 !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
  }
  
  .dropdown-toggle {
    display: flex !important;
    align-items: center !important;
    background: transparent !important;
    white-space: nowrap !important;
    border: none !important;
    gap: 8px !important;
  }
  
  .dropdown-icon {
    color: #9EA0A5 !important;
    height:10px;
    width:10px
  }
  
  .dropdown-toggle::after {
    display: none !important; 
  }
  
  @media (max-width: 430px) {
    .button-week {
      display: none;
    }
    .image-week {
      margin-right: 12px;
      height:40px
    }
    .header-h1 {
      /* margin-left:25px */
    }
  }
  @media (min-width: 431px) {
    .image-week {
      display: none;
    }
  }
  
  @media (max-width: 1100px) {
      .header-h1-1 {
      padding-left: 30px;
      padding-top:10px
    }
  }
  @media (max-width: 800px) {
    .header-h1-1 {
    padding-left: 10px;
    padding-top:10px
  }
  }
  @media (max-width: 500px) {
    .header-h1-1 {
    padding-left: 5px;
    white-space: nowrap;
    font-size:15px !important;
    margin-right:5px !important
    
  }
  }