
.navbar-mark {
  width: 100%;
  height: 114px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  align-items: center;
  border-right: 1px solid rgba(128, 128, 128, 0.151);
}
.navbar {
  width: 255px;
}
.navbar svg {
  font-size: 23px !important;
}
.navbar-mark img:nth-child(1) {
  width: 162.32px;
}

.navbar-mark img:nth-child(2) {
  width: 120px;
}

.header {
  height: 65px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;
  box-sizing: border-box;
  margin-top:6px
}
.css-1c91ggk {
  padding: 18px 0px 0px 50px !important;
}

.landing-contain-content {
  width: 100%;
  height: 100vh;
  background-color: #111214;
  /* overflow: hidden; */
  /* padding-top: 98px; */
}
.landing-contain-content::-webkit-scrollbar {
  display: none;
}
.landing-contain-section {
  width: 100%;
  height: 100%;
  padding: 0px 14px;
  box-sizing: border-box;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiTableContainer-root.css-11xur9t-MuiPaper-root-MuiTableContainer-root::-webkit-scrollbar {
  display: none;
}
.live-ended {
  background-color: rgba(185,216,255,0.1);
  border-radius: 60px;
  height: 42px;
  width: 252px;
  margin-top: 24px;
  /* margin-bottom: 14px; */
  /* margin-left: 20px; */
}

.live-ended button {
  height: 40px;
  width: 125px;
  border-radius: 60px;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  font-family: InterMedium !important;
}

.live-ended .selected {
  color: white;
  background: #1657FF;
  font-weight: 500;
  font-size: 12px;
  font-family: InterMedium !important;
}
.tasks-button {
  display: flex;
  width: 290px;
  justify-content: space-around;
  padding: 5px 5px;
  border: 1px solid #24262B;
  border-radius: 16px;
  /* margin-top: 10px;
  margin-left:20px; */
  gap:5px
}
.tasks-button button {
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  height: 40px;
  width: 132px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-family: Montserrat !important;
  color: white;
  background-color: transparent;
}
.tasks-button .selected {
  background: #B9D8FF1A;
  font-weight: 600;
  border-radius: 20px;
  white-space: nowrap;
}
.text-button
{
  font-size: 33px;
}
.tasks-text{
  font-family: InterBold;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  color: #F4F5F7;
  white-space: nowrap;
}
.tasks-button .selected .text-gradient {
  font-family: InterSemibold;
  font-size: 12px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color:#F4F5F7
  
}

.landing-contain-section .ant-card-body {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border: 1px solid #24262B;
  box-shadow: 0px 1px 2px 0px #1018280D;

  border-radius: 10px !important;
  background-color: transparent;
}
.admin-avartar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* background-color: #090909; */
}

.admin-avartar img {
  width: 51px;
  background-color: #3654d6;
  border-radius: 32px;
  margin-right: 3px;
}

.admin-avartar span {
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.19px;
  text-align: left;
  color: rgba(244, 246, 247, 0.5);
  position: relative;

  top: -5px;
}
.admin-avartar h5 {
  font-family: InterBold;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.63px;
  text-align: left;
  color: #F4F5F7;
  position: relative;

  top: 10px;
}

.navbar .ant-card-body {
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 204px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navbar .ant-card-body h5 {
  margin: 3px !important;
}
.admin-avartar {
  margin-bottom: 28px;
}
.task-table img {
  width: 48px;
  margin-left: 50%;
  transform: translate(-50%, 0);
}

.task-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 24px;
}

.task-table h4 {
  margin-top: 2px;
  margin-bottom: 1px;
}

.task-table p {
  margin-top: 1px;
}
.task-table tr {
  height: 100px;
  /* border-bottom: 1px solid gray !important; */
}

.task-table th,
td {
  border-bottom: 1px solid #f3f3f3;
}

.task-table th {
  text-align: left;
}
.for-border {
  border-collapse: unset !important;
  padding: 28px 30px !important;
}
.tablecell-container {
  /* padding: 16px 16px 16px 0px !important; */
  border-bottom: 1px solid #24262b !important;
}

@media(max-width:1070px)
{
  .header {
  
    margin-top:18px
  }
}

@media (max-width: 450px) {
  .tasks-button {
    width: 100%;
  }
  
  .tasks-button button {
    font-size: 8px;
  }
  .tasks-text
  {
    font-size: 10px !important;
  }
}
@media (max-width: 348px) {
  .tasks-button button {
    font-size: 8px;
  }
  .tasks-text
  {
    font-size: 10px !important;
  }
}
@media (max-width: 340px) {
  .tasks-button button {
    font-size: 8px;
  }
  .tasks-text
  {
    font-size: 8px !important;
  }
 
}
