.css-9mi5l3 {
    border-radius: 34px;
}

/* .navbar :where(.css-dev-only-do-not-override-1pg9a38).ant-menu .ant-menu-item, :where(.css-dev-only-do-not-override-1pg9a38).ant-menu .ant-menu-submenu-title{
    color: gray!important;
} */
 /* .ant-menu-submenu-title{
    color: #09481399!important;
 } */

 /* .ant-menu-submenu-open .ant-menu-submenu-title{
    border-left: 3px solid blue;
 } */
 /* :where(.css-dev-only-do-not-override-1gwfwyx).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-1gwfwyx).ant-menu-light>.ant-menu .ant-menu-item-selected
 {
   background-color: rgba(254,241,246,0.1) !important;
   height:47px !important
 } */
 :where(.css-dev-only-do-not-override-1gwfwyx).ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover, :where(.css-dev-only-do-not-override-1gwfwyx).ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover 
 {
   background-color: rgba(254,241,246,0.1) !important;
   height: 47px !important
 }
 .ant-menu-submenu-selected .ant-menu-submenu-title{ 
   border-left: 4px solid #0f6fff !important;
   /* color: transparent!important; */
   border-radius: 0px;
   box-sizing: border-box!important;
   padding-left: 20px!important;
   background:linear-gradient(90.24deg, #f4f6f7 100%, #F4f6f7 100%) !important;
   background-clip: text !important;
   -webkit-background-clip: text !important;
   -webkit-text-fill-color: transparent !important;
   color: transparent !important;
   background-color: #00B80C1A !important;

}

/* .ant-menu-item{
   color: #09481399 !important;
} */

.ant-menu-item-selected{
   /* color: transparent!important; */
   background:linear-gradient(90.24deg, #f4f6f7 100%, #F4f6f7 100%) !important;
   background-clip: text !important;
   -webkit-background-clip: text !important;
   -webkit-text-fill-color: transparent;
   background-color: #00B80C1A !important;
}

 :where(.css-dev-only-do-not-override-d2lrxs).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-d2lrxs).ant-menu-light>.ant-menu .ant-menu-item-selected 
 {
   color:#F4F6F7  !important;
 }
 
 :where(.css-dev-only-do-not-override-1gwfwyx).ant-card:not(.ant-card-bordered) {
   background: #d60d0d ;
 }