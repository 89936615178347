.qr-code-page{
    width: 100%;
    display: flex;
    padding-top: 50px;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
}

.qr-code-page img{
    width: 300px;
}

.qr-code-page h2{
    margin-top: 50px;
}
.qr-code-page p{
    margin: 0px;
    font-size: 12px;
    font-weight: bold;
}

.qr-code-page input[type=text]{
    width: 70%;
    margin-top: 30px;
    height: 32px;
    border: 1px solid #e1ecff;
    outline: none;
    text-align: center;
}

.qr-code-page .MuiButton-contained{
    margin-top: 0px !important;
    margin-left: 20px;
    background-color: #3350ce;
    border-radius: 20px;
    padding: 10px;
    font-size: 12px;
    text-transform: none;
    font-weight: bold;
}
.qr-code-page .MuiButton-outlined{
    border-radius: 20px;
    font-size: 12px;
    text-transform: none;
    padding: 10px;
    width: 100px;
    font-weight: bold;
}

.qr-code-page .button-group{
    margin-top: 20px;
    width: 80%;
    display: flex;
    flex-direction: row-reverse!important;
}