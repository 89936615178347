.edit-page-left-button-group_1 {
    display: flex !important;
    flex-direction: row !important;
    gap: 20px;
    margin-top: -61px;
    margin-bottom: 337px;
  }
  .edit-page-left-button-group_1button {
    border-radius: 20px;
    width: 190px;
    height: 60px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    text-align: left;
    margin-top:0px
  }
  
  .edit-page-left-button-group_1 .MuiButton-contained {
    border-radius: 16px;
    width: 190px;
    height: 60px;
    font-family: Montserrat-Semibold;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  text-transform:capitalize;
  color: #FFFFFF;
    background: rgba(22, 87, 255, 1) !important;
  }

.edit-page-left-button-group_1 .MuiButton-outlined {
  border-radius: 16px !important;
  background: #393C43 !important;
  
  border:none !important;
  color: white;
  font-family: Montserrat-Semibold !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  text-transform:capitalize;
  height: 60px !important;
  width: 190px !important;
  }
  @media(max-width:768px)
  {
    .edit-page-left-button-group_1
    {
        margin-top:-110px;
        margin-bottom:50px
    }
  }